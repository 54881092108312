<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <div>
    <v-form
      ref="preferencesForm"
      v-model="valid"
      v-if="userPreferences || organizationPreferences"
      valid
    >
      <v-card class="mb-4 mt-5">
        <v-card-title class="text-subtitle-1">
          Persoonlijk
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
            <v-checkbox
              class="mt-n1"
              v-model="userPreferences.uiDarkMode"
              label="Donkere weergave"
            />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-btn
                @click="resetDrawerLeftWidth"
                class="text--secondary"
              >
                Schermverhoudingen herstellen
              </v-btn>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>

      <v-card
        v-if="authHasModuleAccess('MC04', { dataExportEnabled: true })"
        class="mb-4"
      >
        <v-card-title class="text-subtitle-1">
          Organisatiebreed
        </v-card-title>
        <v-card-text>
          <v-card-subtitle>Nieuwsbrief-export kleuren</v-card-subtitle>

          <p class="mb-0">
            Kies hier de kleurinstellingen voor de nieuwsbrief-
            export van jou en je collega’s.
          </p>

          <div class="text-caption pt-2 font-italic">
            <p>
              *Let op: Deze instellingen hebben invloed op de instellingen van andere gebruikers
              binnen jouw organisatie. Door deze aan te passen, pas je de instellingen voor
              iedereen binnen de organisatie aan.
            </p>
          </div>

          <p
            class="color__error"
            v-if="
              !organizationPreferences.mc4PrimaryColor ||
                !organizationPreferences.mc4SecondaryColor
            "
          >
            Primaire en secundaire kleur zijn beide verplicht
          </p>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <div>Primaire kleur</div>
              <v-color-picker
                :mode.sync="mode"
                dot-size="25"
                v-model="organizationPreferences.mc4PrimaryColor"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <div>Secundaire kleur</div>
              <v-color-picker
                :mode.sync="mode"
                dot-size="25"
                v-model="organizationPreferences.mc4SecondaryColor"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
  <!-- eslint-enable vue/no-mutating-props -->
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import { mapGetters } from 'vuex';
import Config from '@/config';

export default {
  name: 'AccountPreferencesFormGeneral',

  props: {
    userPreferences: {
      type: Object,
      default() {
        return {
          uiDarkMode: false,
          drawerLeftWidth: Config.defaultDrawerLeftWidth,
          agendaUpdateTimeOfDay: 7,
          agendaUpdateDayOfWeek: 1,
        };
      },
    },
    organizationPreferences: {
      type: Object,
      default() {
        return {
          mc4PrimaryColor: '#ff4c70',
          mc4SecondaryColor: '#ff4011',
          mc4ExportTemplate: 'newsletter-basic2',
        };
      },
    },
  },

  data() {
    return {
      valid: false,

      mode: 'hexa',
      predefinedColors: Config.predefinedColors,

      debounceTimer: null,
    };
  },

  computed: {
    ...mapGetters({
      authHasModuleAccess: 'authHasModuleAccess',
    }),
  },

  watch: {
    userPreferences: {
      handler: 'submitForm',
      deep: true,
    },
    organizationPreferences: {
      handler: 'scheduleSubmit',
      deep: true,
    },
  },

  methods: {
    resetDrawerLeftWidth() {
      this.userPreferences.drawerLeftWidth = Config.defaultDrawerLeftWidth;
    },

    scheduleSubmit() {
      // show spinner
      this.$emit('update-save-status', 'loading');

      // clear existing timers, we only want to submit once per time period.
      clearTimeout(this.debounceTimer);

      // Call submit after a timeout
      this.debounceTimer = setTimeout(
        this.submitForm,
        1000,
      );
    },

    async submitForm() {
      // Guard to make sure colors stay hex values and do not become Objects.
      // It is unknown why this sometimes happens.
      const primaryColor = this.organizationPreferences.mc4PrimaryColor;
      if (typeof primaryColor !== 'string') {
        this.organizationPreferences.mc4PrimaryColor = primaryColor.hex || '#000000';
      }
      const secondaryColor = this.organizationPreferences.mc4SecondaryColor;
      if (typeof secondaryColor !== 'string') {
        this.organizationPreferences.mc4SecondaryColor = secondaryColor.hex || '#000000';
      }

      // show spinner
      this.$emit('update-save-status', 'loading');
      if (this.$refs.preferencesForm.validate()) {
        const success = await this.$store.dispatch('setPreferences', {
          user: this.userPreferences,
          organization: this.organizationPreferences,
        });

        if (success && success.user && success.organization) {
          this.$vuetify.theme.dark = this.userPreferences.uiDarkMode;
          this.$store.dispatch('setMessage', {
            message: 'Instellingen zijn succesvol opgeslagen',
            type: 'success',
          });

          // show saved
          this.$emit('update-save-status', 'saved');
        } else {
          this.$store.dispatch('setMessage', {
            message: 'Fout bij het opslaan van de instellingen',
            type: 'error',
          });

          // hide spinner
          this.$emit('update-save-status', null);
        }
      } else {
        // hide spinner
        this.$emit('update-save-status', null);
      }
    },
  },
};
/* eslint-enable vue/no-mutating-props */
</script>

<style lang="scss">
.input--color input {
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  width: 140px;
}

.preferences-tooltip {
  margin: 1em 0;
  word-break: break-word;
  background: whitesmoke;
  padding: 1em;
  border-radius: 5px;
}

.color__error {
  color: #f56c6c;
  font-size: 12px;
  text-align: center;
  margin-bottom: 2em;
}

.color-preview {
  width: 32px;
  height: 34px;
  position: absolute;
  left: 105px;
  display: block;
  top: 4px;
  border-radius: 3px;
}
</style>
