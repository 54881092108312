<template>
  <div
    class="loader"
    :class="{ 'loader--busy': busy }"
  >
    <v-progress-linear
      color="teal"
      :indeterminate="true"
      class="ma-0"
      height="4"
      style="top: -2px;"
    />
  </div>
</template>

<style>
.v-progress-linear {
  -moz-transform: scale(1, -1);
  -webkit-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
</style>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppbarProgressBar',

  computed: {
    ...mapGetters({
      busy: 'globalLoaderBusy',
    }),
  },
};
</script>

<style lang="scss" scoped>

.loader {
  visibility: hidden;
  height: 6px;
}

.loader--busy {
  visibility: visible;
}

</style>
