<!--
  Component which displays the username
-->

<template>
  <div class="nav nav--account">
    <v-menu
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-app-bar-nav-icon
          v-if="$vuetify.breakpoint.mdAndDown"
          class=""
          v-bind="attrs"
          v-on="on"
        >
          <v-icon size="28">
            mdi-account
          </v-icon>
        </v-app-bar-nav-icon>
        <v-btn
          v-else-if="$vuetify.breakpoint.lgAndUp"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon
            class="mr-2"
          >
            mdi-account
          </v-icon>
          {{ username }}
          <v-icon>
            mdi-menu-down
          </v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="openPreferences()">
          <v-list-item-title>Instellingen</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logoutUser()">
          <v-list-item-title>Uitloggen</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { showDialog } from '@/helpers/dialogHelper';
import AccountPreferencesForm from '../AccountPreferencesMenu/AccountPreferencesForm.vue';

export default {
  name: 'NavAccount',

  methods: {
    ...mapActions({
      logout: 'logout',
    }),
    openPreferences() {
      this.$gtag.event('account-preferences', {
        event_category: 'account',
      });

      // We need to spread user and organization separately in order to avoid reference issues with references to
      // properties of user and organization.
      // If not, we get the 'Do not mutate vuex store state outside mutation handlers' error, since references
      // to the vuex store are passed as a prop of the AccountPreferencesForm
      showDialog(AccountPreferencesForm, {
        userPreferences: { ...this.userPreferences },
        emailPreferences: this.userEmailPreferences,
        emailTempateOptions: this.userEmailTempateOptions,
        organizationPreferences: { ...this.organizationPreferences },
      });
    },
    logoutUser() {
      this.$gtag.event('account-logout', {
        event_category: 'account',
      });

      this.logout().then((result) => {
        if (result.status === 200) {
          this.$router.push({ name: 'login' });
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'authIsAuthenticated',
      username: 'userName',
      userPreferences: 'userPreferences',
      userEmailPreferences: 'userEmailPreferences',
      organizationPreferences: 'organizationPreferences',
      authHasModuleAccess: 'authHasModuleAccess',
    }),
  },
};
</script>

<style scoped lang="scss">

</style>
